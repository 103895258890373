import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";
import {
	TYPOGRAPHY,
	FONT_WEIGHT,
	ButtonSizes,
	ButtonVariantStyles,
	TextButtonSizes,
	TextButtonVariantStyles,
} from "@/theme";

import type { ButtonProps, TextButtonProps } from "./types";

/**
 * Button component
 * the button can vary in size and variant
 * @default size: XL, variant: primary
 * Design: https://www.figma.com/file/cYVmvu20RplQZs7ns7qxHM/Website-Components?type=design&node-id=3916-1516&mode=design&t=GicRN5BgCeNjJUQF-0
 */
export const Button = styled.button<PropsWithTheme<HTMLButtonElement> & ButtonProps>`
	${TYPOGRAPHY.buttonLabels.MD};
	font-weight: ${FONT_WEIGHT.medium};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	${({ size = "XL", variant = "primary", useMobileButtonBehaviour = false, theme }) => css`
		${ButtonSizes[size]}
		${ButtonVariantStyles[variant]}
		
		${useMobileButtonBehaviour &&
		`
			width: 100%;
			@media ${theme.mq.m} {
				width: auto;
			}
		`}

		&:hover {
			${ButtonVariantStyles[variant].hover}
		}

		&:active {
			${ButtonVariantStyles[variant].active}
		}

		&:disabled {
			${ButtonVariantStyles[variant].disabled}
		}

		&:loading {
			${ButtonVariantStyles[variant].loading}
		}
	`};
`;

/**
 * Button component – variant "Text"
 * the text button can vary in size and variant
 * @default size: XL, variant: primaryText
 * Design: https://www.figma.com/file/cYVmvu20RplQZs7ns7qxHM/Website-Components?type=design&node-id=3916-1516&mode=design&t=GicRN5BgCeNjJUQF-0
 */
export const TextButton = styled.button<PropsWithTheme<HTMLButtonElement> & TextButtonProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	${({ size = "XL", variant = "primaryText", useMobileButtonBehaviour = false, theme }) => css`
		${TextButtonSizes[size]}
		${TextButtonVariantStyles[variant]}
 
		${useMobileButtonBehaviour &&
		`
			width: 100%;
			@media ${theme.mq.m} {
				width: auto;
			}
		`}

		&:hover {
			${TextButtonVariantStyles[variant].hover}
		}

		&:active {
			${TextButtonVariantStyles[variant].active}
		}

		&:disabled {
			${TextButtonVariantStyles[variant].disabled}
		}

		&:loading {
			${TextButtonVariantStyles[variant].loading}
		}
	`};
`;

export const FloatingButton = styled(Button)`
	border-radius: 40px;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	cursor: pointer;
	display: flex;
	height: 40px;
	gap: 6px;
	padding: 7px 10px;
	place-items: center;
	${({ theme }) => css`
		border: 2px solid ${theme.palette.freeze[0]};
		color: ${theme.palette.freeze[0]};
		${{ ...theme.typography.buttonLabels["MD"] }}
	`}
`;

export const LinkButton = Button.withComponent("a");
